import React from 'react';
import { Card, CardContent, Stack, SvgIcon, Typography } from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import Client from '../Client';
import Config from '../ClientConfig';

function thousandsSeparator(x) {
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

class OverviewTotalTransactions extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      client: new Client ( "none" ),
      sales_total: [],
      filterData: {}
    };

    this.getData = this.getData.bind(this);
    this.doSync = this.doSync.bind(this);

  }

  componentDidMount() {
    this.getData ( );
  }

  getData ( ) {
    let queryData = { "type": 1 }
	let data = this.state.client.getData ( Config.API_URL_REPORTS_FINANCIAL_SALES, queryData );
    if ( data ) {
      this.setState ( { sales_total: data });
    }
    this.setState ( { filterData: queryData } );
  }

  doSync ( ) {
    this.getData ( );
  }

  render() {
    let salesTYB = 0;
    let salesLYB = 0;
    let salesTodayTY = 0;
    let salesTodayLY = 0;
    let diff_salesB = 0;
    let diff_posB = false;

    if ( this.state.sales_total ) {
        let sales_total = this.state.sales_total;
        if ( sales_total.bon_TY !== undefined ) salesTYB = parseInt(sales_total.bon_TY);
        if ( sales_total.bon_LY !== undefined ) salesLYB = parseInt(sales_total.bon_LY);
        if ( sales_total.bon_Today !== undefined ) salesTodayTY = parseInt(sales_total.bon_Today);
        if ( sales_total.bon_TodayLY !== undefined ) salesTodayLY = parseInt(sales_total.bon_TodayLY);
        if ((salesLYB-salesTodayLY) >0 ) diff_salesB = parseFloat(((salesTYB - salesTodayTY) / (salesLYB-salesTodayLY) -1)*100).toFixed(1);
        diff_posB = (diff_salesB >= 0) ? true : false;
    }

    return (
      <Card sx={{ borderRadius:"16px", height: '100%' }}>
        <CardContent>
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="center"
            spacing={3}
          >
            <Stack spacing={1}>
              <Typography
                sx={{lineHeight: 1.5}}
                color="#1a237e"
                variant="overline"
              >
                ORDERS THIS YEAR
              </Typography>
              <Typography variant="h4">{thousandsSeparator((salesTYB/1000).toFixed(1))+"K"}</Typography>
            </Stack>
            {/* <Avatar
              sx={{
                backgroundColor: 'primary.main',
                height: 56,
                width: 56
              }}
            >
              <SvgIcon><EuroSymbolIcon /></SvgIcon>
            </Avatar> */}
          </Stack>
            <Stack
              alignItems="center"
              justifyContent="center"
              direction="row"
              spacing={2}
              sx={{ mt: 2 }}
            >
              <Stack
                alignItems="center"
                direction="row"
                spacing={0.5}
              >
                <SvgIcon
                  color={diff_posB ? 'success' : 'error'}
                  fontSize="small"
                >
                  {diff_posB ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
                </SvgIcon>
                <Typography
                  color={diff_posB ? 'success.main' : 'error.main'}
                  variant="body2"
                >
                  {diff_salesB}%
                </Typography>
              </Stack>
              <Typography
                color="text.secondary"
                variant="caption"
              >
                vs. last year
              </Typography>
            </Stack>
        </CardContent>
      </Card>
    );

  }
}

export default OverviewTotalTransactions;